<template>
  <b-container>
    <validation-observer
      #default="{ invalid }"
      ref="simpleRules"
    >
      <b-form
        ref="form"
        :style="{height: trHeight}"
        class="repeater-form"
        @submit.prevent="repeateAgain"
      >
        <b-card>
          <div>
            <b-row
              v-for="(item) in items"
              :id="item.id"
              :key="item.id"
              ref="row"
            >
              <b-col md="3">
                <b-form-group
                  label="Fundo"
                  label-for="type"
                >

                  <b-form-select
                    id="type"
                    v-model="item.fund"
                    disabled
                    :options="funds"
                  />
                </b-form-group>
              </b-col>

              <b-col md="9">
                <b-form-group
                  label="Lâmina"
                  label-for="sheet"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Lâmina"
                    rules="required|pdf"
                  >
                    <b-form-file
                      id="sheet"
                      v-model="item.sheet"
                      placeholder="Arraste ou selecione um arquivo"
                      drop-placeholder="Arraste o arquivo aqui."
                      browse-text="Procurar"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <hr>
              </b-col>
            </b-row>

          </div>
        </b-card>
        <b-button
          :disabled="invalid"
          variant="primary"
          class="m-auto d-block"
          @click="formValidate"
        >
          Lançar
        </b-button>
      </b-form>
    </validation-observer>
  </b-container>
</template>

<script>
import {
    BForm, BFormSelect, BFormGroup, BRow, BCol, BButton, BCard, BContainer, BFormFile,
} from 'bootstrap-vue';
import { heightTransition } from '@core/mixins/ui/transition';
import Ripple from 'vue-ripple-directive';

import { ValidationProvider, ValidationObserver } from 'vee-validate';
import PtahService from '@/service/ptah/register-fund';

import '@validations';

export default {
    components: {
        BCard,
        BForm,
        BFormSelect,
        BRow,
        BCol,
        BButton,
        BFormGroup,
        BFormFile,
        BContainer,
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    mixins: [heightTransition],
    data() {
        return {
            showLoading: false,
            items: [{
                id: 'd2bf8c1e-028c-4af8-951b-858466d88f31',
                file: null,
                fund: 'FIC',
            },
            {
                id: '793949ce-5db8-465f-9964-b305bf5b5ec4',
                file: null,
                fund: 'ViaInvest',
            },
            {
                id: 'a444fa03-6f7a-40f6-8e3e-b2820f01d970',
                file: null,
                fund: 'Multiplik',
            },
            {
                id: '0187aa56-0e02-4530-9dce-7a0fcb772ce7',
                file: null,
                fund: 'ViaInvest II LP FIDC',
            },
            ],
            funds: [{ value: 'FIC', text: 'FIC' },
                { value: 'Multiplik', text: 'Multiplik' },
                { value: 'ViaInvest', text: 'Viainvest' },
                { value: 'ViaInvest II LP FIDC', text: 'ViaInvest II LP FIDC' },
            ],

        };
    },
    mounted() {
        this.initTrHeight();
    },
    created() {
        window.addEventListener('resize', this.initTrHeight);
    },
    destroyed() {
        window.removeEventListener('resize', this.initTrHeight);
    },
    methods: {
        initTrHeight() {
            this.trSetHeight(null);
            this.$nextTick(() => {
                this.trSetHeight(this.$refs.form.scrollHeight);
            });
        },
        formValidate() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.release();
                }
            });
        },
        async release() {
            const response = await this.confirmAnAction('Você deseja realmente lançar os fundos?');

            if (!response) return;

            const body = this.items.map(item => ({
                fund_id: item.id,
                sheet: item.sheet,
            }));
            this.callLoading(true);
            const allResponse = await Promise.all(body.map(item => PtahService.registerSheet(item)));
            this.callLoading(false);
            const hasError = allResponse.some(item => item.status !== 200);

            if (hasError) {
                await this.modalError('Houve um erro ao lançar as Lâminas');
                return;
            }

            this.modalSuccess('Lâminas lançados com sucesso');
        },

    },
};
</script>

  <style lang="scss">
    .repeater-form {
      overflow: hidden;
      transition: .35s height;
    }
  </style>
